.postLogin {
  width: 100vw;
  height: 100vh;

  & > .navbar {
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;
    border-bottom: 1px solid #e9ebf3;
    position: relative;
    background:  linear-gradient(89.14deg, #F04E8C 0.79%, #F8BA4E 143.99%);
    z-index: 99999;

    .inputContainer {
      border: 0.5px solid #e5e5e5;
      padding: 10px 1rem;
      background: #f5f5f5;
      font-size: 0.9rem;
      display: block;
      outline: none;
      font-weight: 600;
      border-radius: 5px;
      max-width: 450px;
      width: 350px;
      margin-left: 18px;
      /* width: 50%; */
      transition: all linear 0.3s;
      background: white;

      &::placeholder {
        color: #0b2447;
        font-weight: 600;
      }
    }

    & > img {
      height: 70%;
      object-fit: contain;
      transition: all linear 0.3s;
    }

    & > .menuBar {
      position: relative;
      display: flex;
      justify-content: flex-end;
      gap: 1.5rem;
      align-items: center;
      width: 450px;
      transition: all linear 0.3s;

      & > .menuBtn {
        display: flex;
        // border: 0.5px solid #e5e5e5;
        border-radius: 5px;
        cursor: pointer;
        transition: all linear 0.3s;
        color: white;

        & > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 500;
          transition: all linear 0.3s;

          padding: 5px 1rem;
          text-align: center;
          border-radius: 5px;
          font-size: 0.85rem;
          //
          // margin-right: 20px;

          // svg {
          //   margin-left: 15px;
          // }
        }

        & > :nth-child(1) {
          transition: all linear 0.3s;
          border-radius: 10rem;
          width: 36px;
          height: 36px;
          margin-right: 43px;
          padding-left: 11px;

          &:hover {
            // background: var(--main-color);
            // color: white;
            transform: scale(1.03);
          }

          &:hover {
            // background: var(--main-color);
            // color: white;
            transform: scale(1.03);
          }
        }

        & > :nth-child(2) {
          transition: all linear 0.3s;
          border-radius: 10rem;
          width: 36px;
          height: 36px;
          border: 0.5px solid #e5e5e5;
          background: white;

          &:hover {
            // background: var(--main-color);
            // color: white;
            transform: scale(1.03);
          }
        }

        & > :nth-child(3) {
          transition: all linear 0.3s;
          border-radius: 10rem;
          width: 36px;
          height: 36px;
          border: 0.5px solid #e5e5e5;
          background: white;

          &:hover {
            // background: var(--main-color);
            // color: white;
            transform: scale(1.03);
          }
        }

        & > .profileDiv {
          padding: 10px 0.7rem;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          background: transparent;

          & > .notificationIcon {
            height: 1.2rem;
            border-radius: 50%;
            cursor: pointer;
          }

          & > .dropdownContent {
            position: absolute;
            top: 140%;
            right: 1rem;
            z-index: 5;
            background: white;
            border-radius: 10px;
            padding: 1rem;
            border: 1px solid #e1e1e1;
            transition: all linear 0.3s;
            overflow: scroll;
            max-height: 500px;
            width: 400px;
            overflow-y: scroll;
            padding-bottom: 0;
            text-align: left;

            & > .singleNotification {
              display: flex;
              flex-direction: column;
              gap: 0.4rem;
              margin-bottom: 2rem;

              & > .heading {
                display: flex;
                align-items: center;
                gap: 1px;

                & > div {
                  display: flex;
                  align-items: center;
                  gap: 3px;
                }

                & > .title {
                  font-size: 0.8rem;
                  font-weight: 500;

                  & > img {
                    height: 1rem;
                    border-radius: 3px;
                  }
                }

                & > .duration {
                  font-size: 0.7rem;
                  font-weight: 400;
                }

                & > .closeBtn {
                  margin-left: auto;
                  font-size: 0.8rem;
                  font-weight: 550;
                  cursor: pointer;
                  border: none;
                }
              }

              & > .notificationTitle {
                font-size: 0.9rem;
                font-weight: 500;
                margin: 3px 0;
              }

              & > .notificationMessage {
                font-size: 0.75rem;
                font-weight: 400;
              }

              & > .notificationImage {
                width: 100%;
                object-fit: contain;
                border-radius: 10px;
              }

              & > .notificationBtn {
                border-radius: 5px;
                border: 1px solid #e1e1e1;
                box-shadow: 0px 4px 4px 0px #5a565640;
                background: #fff;
                font-weight: 550;
                cursor: pointer;
                font-size: 0.8rem;
                padding: 0.5rem;
                text-align: center;
                transition: all linear 0.3s;

                &:hover {
                  background: var(--main-color);
                  color: white;
                }
              }
            }

            &::-webkit-scrollbar {
              display: none;
              width: 0px;
              /* Set the width of the table's scrollbar */
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              height: 7px;
            }
          }
        }
      }

      & > .menuDropdown {
        position: absolute;
        top: 130%;
        right: 2rem;
        background: white;
        border-radius: 10px;
        border: 1px solid #dddddd;
        box-shadow: 0px 4px 4px 0px #5a565640;
        padding: 1rem;
        width: 300px;
        max-height: 80vh;
        // overflow-y: scroll;
        display: flex;
        z-index: 3;

        &::-webkit-scrollbar {
          width: 0px !important;
          height: 0px !important;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        & > .mainMenu {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          width: 100%;
          padding: 1rem 0;
          // padding-bottom: 3rem;

          & > .eachItem {
            display: flex;
            align-items: center;
            gap: 1rem;
            padding: 0.8rem 1rem;
            border-radius: 15px 0 0 15px;
            cursor: pointer;
            // transition: all linear 0.3s;

            & > .imageDiv {
              border: 1px solid #dddddd;
              padding: 5px;
              border-radius: 50%;
              height: 2.5rem;
              width: 2.5rem;
              display: flex;
              justify-content: center;
              align-items: center;
              background: white;

              & > img {
                width: 75%;
                object-fit: contain;
              }
            }

            & > :nth-child(2) {
              font-size: 1rem;
              font-weight: 450;
              white-space: nowrap;
            }
          }

          & > .eachItemSelected {
            background: #f1f4f6bf;
            font-weight: 550;
          }

          & > :nth-child(5) {
            margin-bottom: 4rem;
          }

          & > .settingsButton {
            padding: 0.8rem 1rem;
            border-radius: 15px 0 0 15px;
            margin-top: 0rem;
            cursor: pointer;

            &:hover {
              // color: var(--main-color);
              transform: scale(1.03);
            }
          }
        }

        & > .subMenu {
          width: 50%;
          background: #f1f4f6bf;
          padding: 1rem;
          padding-bottom: 3rem;
          overflow-y: scroll;

          & > .subHeading {
            font-weight: 600;
            // padding-top: 0.7rem;
          }

          & > div {
            padding: 1.2rem 0;
            font-size: 1rem;
            border-bottom: 1px solid #e7e7e7;
            cursor: pointer;
            height: 4rem;
            padding-left: 10px;
            display: flex;
            align-items: center;

            &:hover {
              background: white;
            }
          }
        }
      }
    }
  }

  & > .layoutContainer {
    display: flex;
    height: calc(100% - 3.5rem);

    & > .sidebar {
      height: 100%;
      display: flex;
      position: relative;

      & > .mainMenu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #fff;
        width: 70px;
        position: relative;
        border-right: 1px solid #dddddd;

        & > div {
          width: 100%;
        }

        & > .sidebarMenu {
          display: flex;
          flex-direction: column;
          gap: 10px;
          //  padding: 10px;
          overflow: scroll;

          & > .eachItem {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 15px 5px;
            gap: 6px;
            cursor: pointer;
            aspect-ratio: 1 / 1;
            transition: all linear 0.3s;

            & > svg {
              width: 50%;
              object-fit: contain;
              // fill: #d2d2d2;
              fill: #5a5656 !important;
              transition: all linear 0.3s;
            }

            & > .title {
              font-size: 0.7rem;
              font-weight: 500;
              text-align: center;
              // color: #d2d2d2;
              color: #5a5656;

              // text-overflow: ellipsis;
              // overflow: hidden;
              width: 100%;
              word-wrap: break-word;
              transition: all linear 0.3s;
            }

            &:hover {
              & > svg {
                fill: var(--main-color);
                transform: scale(1.06);
              }

              & > .title {
                transform: scale(1.06);
                color: #5a5656;
              }
            }
          }

          & > .eachItemSelected {
            background: #F04E8C 0f;

            & > svg {
              fill: #F04E8C  !important;
            }

            & > .title {
              font-weight: 600;
              color: #F04E8C  !important;
              margin-top: 4px;
            }
          }
        }

        & > .settingsMenu {
          & > .eachItem {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1rem;
            cursor: pointer;
            transition: all linear 0.3s;

            &:hover {
              & > svg {
                fill: var(--font-color);
              }
            }

            & > svg {
              width: 30%;
              object-fit: contain;
              fill: #ffffff;
              transition: all linear 0.3s;
            }
          }
        }

        & > .arrowBox {
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 50%;
          position: absolute;
          top: 0;
          right: -1.5rem;
          transform: translate(-50%, -50%);
          border: 1px solid #e9ebf3;
          display: flex;
          align-items: center;
          justify-content: center;
          background: white;
          color: var(--main-color);
          cursor: pointer;
          z-index: 99;
          transition: all linear 0.3s;

          & > svg {
            height: 65%;
          }
        }
      }

      & > .collapseSidebar {
        width: 214px;

        inset: 0;
        left: 100%;
        z-index: 5;
        background: white;
        padding: 1.5rem 0;
        border-right: 1px solid #dddddd;
        padding-top: 0;

        & > .collapseSidebarOptions {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          & > a {
            all: unset;
            padding: 1rem 1rem;

            font-weight: 450;
            cursor: pointer;
            font-size: 0.9rem;
            transition: all linear 0.3s;

            &:hover {
              color: #F04E8C ;
              font-weight: 700;

              transform: translateX(8px);
            }
          }

          & > .selectedCollapsedItem {
            background: #F04E8C 0f;
            color: #F04E8C ;
            border-right: 5px solid;

            font-weight: 700;

            transform: translateX(0) !important;
          }
        }
      }
    }

    & > .childContainer {
      width: calc(100% - 90px);
      background: #fbfbfb;
    }
  }

  & > .overFlowMain {
    position: fixed;
    inset: 0;
    z-index: 999999;
    background: #00000080;

    & > .contentContainer {
      background: white;
      padding: 1.5rem 2.5rem;
      border-radius: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 1.5rem;

      & > img {
        height: 6rem;
      }

      & > .desc {
        font-size: 1.3rem;
        font-weight: 500;
        line-height: 1.6;
        max-width: 450px;
        text-align: center;
        color: #000000;
      }

      & > .submitBtn {
        background: linear-gradient(
          90deg,
          #0b2447 0%,
          #0d264c 31.1%,
          #122e5a 67.68%,
          #19376d 100%
        );
        color: white;
        padding: 0.7rem 1.2rem;
        width: max-content;
        border-radius: 7px;
        cursor: pointer;
        transition: all linear 0.3s;

        &:hover {
          scale: 1.05;
        }
      }
    }
  }
}
