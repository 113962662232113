.pipelineSubmenu {
  padding: 2rem;
  height: calc(100% - 2rem);
  width: 100%;
  padding-bottom: 0;
  display: flex;
  gap: 1.5rem;

  &>.mainContainer {
    width: -webkit-fill-available;
    // overflow-y: scroll;
    max-width: 1200px;
    margin: auto;
    border-radius: 10px;
    height: 100%;
    background: white;
    padding: 1.5rem;

    &>.headerContainer {
      margin-bottom: 2rem;
    }

    &>.contentContainer {
      border: 1px solid #e5e5e5;
      border-radius: 10px;
      padding: 1rem;
      background: white;

      &> :nth-child(1) {
        margin-top: 0;
      }
    }
  }
}

.preEnrollmentForm {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-top: 2rem;
  height: calc(100vh - 21rem);
  overflow: scroll;

  &>.eachQuestion {
    color: #5a5656;

    &>.title {
      font-size: 1.1rem;
      margin-bottom: 0.7rem;
    }

    &>.answer {
      font-size: 0.9rem;
      padding: 1rem 1.2rem;
      border: 1px solid #e1e1e1;
      border-radius: 7px;
    }
  }
}

.perscribe {
  padding-top: 2rem;
  display: flex;
  // flex-direction: column;
  gap: 2rem;
  align-items: self-start;
  height: calc(100vh - 21rem);
  overflow: scroll;

  .leftside {
    display: flex;
    flex-direction: column;
    width: 42%;
    height: 99%;
    overflow: scroll;
    width: 42%;
  }

  .title {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    text-align: left;
    color: #5A5656;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    transition: all linear 0.15s;

    svg {
      margin-right: 10px;
      cursor: pointer;

      &:hover {
        transform: scale(1.01) translateX(1px);
      }
    }
  }

  .Addto {
    background: linear-gradient(0deg, #F04E8C  0%, #F79540 100%);
    width: 100%;
    height: 54px;

    border-radius: 10px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.5px;
    text-align: center;
    margin-top: 1.5rem;
    color: #fff;
    align-items: center;
    justify-content: center;
    display: flex;

    &:hover {
      transform: scale(1.01);
    }


  }

  .tablesection {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1.5rem;

    div {
      border: 1px solid #E5E5E5;
      width: 206px;
      height: 58px;
      padding-left: 1rem;
      border-radius: 5px;
      display: flex;
      align-items: center;
      font-family: Montserrat;
      font-size: 13px;
      font-weight: 500;
      line-height: 15.85px;
      text-align: left;
      color: #5A5656;
      cursor: pointer;

      &:hover {
        transform: scale(1.01);
      }
    }
  }

  .selectdiv {
    font-family: Montserrat;
    font-size: 13px;
    font-style: italic;
    font-weight: 500;
    line-height: 15.85px;
    text-align: left;
    color: #5A5656;
    text-decoration: underline;
    margin-top: 10px;
    margin-bottom: 1.5rem;
  }

  .draginput {

    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.85px;
    text-align: left;
    color: #5A5656;
    width: 100%;
    height: 58px;
    border: 1px solid #E5E5E5;
    border-radius: 10px;
    padding: 0 1.2rem;

  }

  .rightsubdiv {
    padding-top: 1.5rem;
    overflow: scroll;
    height: calc(100% - 6rem);
    margin-bottom: 36rem;
  }

  .rightside {
    border: 1px solid #E5E5E5;
    height: calc(100% + 0px);
    width: 50%;
    padding: 1.2rem;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    text-align: left;
    color: #5A5656;
    border-radius: 15px;

    position: relative;

    .bottomlabel {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      align-items: start;

      .leftside {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 700;
        line-height: 19.5px;
        background: linear-gradient(89.14deg, #F04E8C 0.79%, #F8BA4E 143.99%);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #FFFFFF;
        width: 50%;
        height: 54px;

        border-radius: 0px 0px 0px 15px;


      }


      .rightside {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 700;
        line-height: 19.5px;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 700;
        line-height: 19.5px;
        background: linear-gradient(0deg, #F04E8C  0%, #F79540 100%);

        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #FFFFFF;
        width: 50%;
        height: 54px;

        border-radius: 0px 0px 0px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #FFFFFF;
        width: 50%;
        height: 54px;

        border-radius: 0px 0px 15px 0px;
      }

    }
  }

  .eachOptionright {
    font-size: 1rem;
    padding: 1.2rem 1.2rem;
    border: 1px solid #e1e1e1;
    border-radius: 7px;
    font-weight: 500;
    cursor: pointer;
    transition: all linear 0.15s;
    margin-bottom: 0.8rem;
    // display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      transform: scale(1) translateY(-5px);
    }
  }

  .questionsection {
    margin-top: 1rem;
  }

  .close {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.63px;
    text-align: center;
    color: #5A5656;
    width: 97px;
    height: 34px;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;

    &:hover {
      transform: scale(1.02);
    }
  }

  .closesection {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .questionsectBox {
    width: 100%;
    border: 1px solid #E5E5E5;
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    //display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0.8rem 1rem;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    // background: #FFF7F3;

    &:nth-child(1n) {
      background: #F3F4F6;
    }

    &:nth-child(2n) {

      background: #FFF7F3;
    }

    &:hover {
      transform: scale(1) translateY(-2px);
    }

    svg {
      margin-left: 8px;
    }
  }

  .questionsectionName {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 600;
    line-height: 25px;
    text-align: left;
    color: #5A5656;
    margin-bottom: 1rem;

  }

  .listItem {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.85px;
    text-align: left;
    color: #5A5656;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
  }

  .eachOption {
    font-size: 1rem;
    padding: 1.2rem 1.2rem;
    border: 1px solid #e1e1e1;
    border-radius: 7px;
    font-weight: 500;
    cursor: pointer;
    transition: all linear 0.15s;
    margin-bottom: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      transform: scale(1) translateY(-5px);
    }
  }
}

.actionBtns {
  display: flex;
  align-items: center;
  gap: 1rem;

  &>.statusBtn {
    display: flex;
    align-items: center;
    padding: 0.2rem 1rem;
    background: var(--main-color);
    color: white;
    border-radius: 7px;
    font-size: 13px;
    font-weight: 600;

    &>div {
      padding: 10px;
      display: flex;
      justify-content: space-between;
    }

    &> :last-child {
      position: relative;

      &> :nth-child(1) {
        display: flex;
        align-items: center;
        gap: 1.6rem;
        cursor: pointer;
      }

      &>.dropdownList {
        border: 1px solid #e1e1e1;
        border-radius: 6px;
        position: absolute;
        top: 115%;
        left: -0.5rem;
        right: -0.5rem;
        background: white;
        color: black;

        &>.dropdownItems {
          padding: 0.6rem 0.7rem;
          cursor: pointer;
          border-radius: 6px;
          transition: all linear 0.3s;

          &:hover {
            color: white;
            background: var(--main-color);
          }
        }
      }
    }
  }

  &> :nth-child(2) {
    padding: 0.75rem 1rem;
    height: unset;
  }
}

.profileName {
  &>svg {
    height: 3.5rem;
    transition: all linear 0.3s;

    &:hover {
      scale: 1.15;
    }
  }
}