.dropdownParentSectioncustom {
    position: relative;
    width: 206px;


    .dropdown-planb {
        border-radius: 5px;
        background: #fff;
        height: 36px;
        width: 100%;
        color: #ffff;
        font-family: Montserrat;
        padding: 0 0.7rem;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        border: 1px solid #fff;
        background: transparent;

        svg {
            margin-left: 15px;
        }
    }

    .dropdwonlist {
        border-radius: 5px;
        background: #fff;
        padding: 1rem;
        position: absolute;
        top: 40px;
        width: 100%;
        left: 0;
        margin: auto;
        right: 0;
        z-index: 1;
        white-space: break-spaces;
        border: 1px solid #E5E5E5;

        .listitemName {
            display: flex;
            align-items: center;
            color: #5F6163;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 1rem;
            cursor: pointer;

            &:nth-last-child(1) {
                margin-bottom: 0rem;
            }

            img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }

            &:hover {
                transform: scale(1.01);
            }

        }
    }
}

.textcustomhoveEffect {
    position: absolute;
    /* right: auto; */
    z-index: 99;
    font-size: 0.7rem;
    font-weight: 500;
    text-align: center;
    color: #5A5656;
    width: 100%;
    word-wrap: break-word;
    transition: all linear 0.3s;
    border: 1px solid #ddd;
    padding: 6px 2rem;
    width: auto;
    left: 57px;
    background: white;
    border-radius: 5px;
}

.styles-module_tooltip__mnnfp {
    padding: 8px 16px;
    border-radius: 3px;
    font-size: 90%;
    width: max-content;
    z-index: 99;

    border: 1px solid #ddd;
    color: #fff !important;
    left: 70px !important;
    background: #5A5656 !important;
    font-size: 12px;
}