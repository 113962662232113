.innerSectionSession {
    height: calc(100vh - 23rem);
    background: white;
    padding: 21px;
    /* padding-top: 0; */
    padding-top: 2rem;

    overflow: scroll;
    margin-top: 1.5rem;
    position: relative;

    .widgetparent {
        border: 1px solid #E9EBF3;
        padding: 2rem;
        margin-bottom: 1.5rem;
        border-radius: 10px;
        position: relative;
    }

    .namewidget {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: left;
        color: #5A5656;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
            cursor: pointer;
        }

    }

    .widgetparent {
        border: 1px solid #E9EBF3;
        padding: 2rem;
        margin-bottom: 1.5rem;
        border-radius: 10px;
        position: relative;
    }

    .saveSection {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        div {
            border: 1px solid #E9EBF3;
            width: 158px;
            height: 36px;

            border-radius: 5;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 700;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-top: 1rem;

            &:hover {
                transform: scale(1.03);
            }

        }
    }

    .questionsection {
        margin-top: 2rem;
        position: relative;

        &:nth-child(1) {
            margin-top: 0rem;
        }

        .queTitledrop {
            border: 1px solid #E9EBF3;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: left;
            height: 46px;
            padding: 0 10px;
            border-radius: 5px;
            cursor: pointer;
            position: relative;
            width: 100%;

            svg {
                position: absolute;
                right: 10px;
            }
        }

        .dropdonwlistdata {
            border: 1px solid #E9EBF3;
            border-radius: 10px;
            border-radius: 10px;
            width: 200px;
            padding: 1.5rem;
            position: absolute;
            background: white;
            z-index: 1;
            top: 4.5rem;



            div {
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 600;
                line-height: 15px;
                letter-spacing: 0em;
                text-align: left;
                color: #5A5656;
                cursor: pointer;
                margin-top: 1rem;

                &:nth-child(1) {
                    margin-top: 0rem;
                }

                &:hover {
                    transform: scale(1.03);
                }
            }
        }

        .queTitle {
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: left;
            color: #5A5656;
            margin-bottom: 1rem;
        }

        .queAnswer {
            border: 1px solid #E9EBF3;
            height: 36px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            padding-left: 10px;
            font-family: Montserrat;
            font-size: 13px;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: left;
            color: #5A5656;
            width: 158px;
        }

        .queAnswerinput {
            border: 1px solid #E9EBF3;
            height: 46px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            padding-left: 10px;
            font-family: Montserrat;
            font-size: 13px;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: left;
            color: #5A5656;
            width: 100%;

            &:focus-visible {
                outline: -webkit-focus-ring-color auto 0px !important;
            }
        }
    }

    img {
        width: 47px;
        height: 47px;
        border-radius: 54rem;

        margin-right: 14px;
    }
}


.deitpaldroplist {
    background: #FFFFFF;
    width: 100%;
    padding: 0.5rem;
    border-radius: 5px;

    border: 1px solid #E5E5E5;
    margin-top: 1rem;

    .name {
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 400;
        line-height: 25px;
        text-align: left;
        color: #5A5656;

    }

    .inner {
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    input {
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 500;
        line-height: 25px;
        text-align: right;
        color: #5A5656;
        border: 0px solid;
        width: 43px;
        height: 100%;
        display: flex;
        align-items: center;

    }
}