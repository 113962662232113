.loginPage {
  width: 100vw;
  height: 100vh;
  background: #fbfbfb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &>.topNav {
    position: fixed;
    top: 0;
    left: 0;
    height: 3rem;
    display: flex;
    align-items: center;
    width: max-content;
    z-index: 1;

    &>img {
      height: 2.2rem;
      width: 2.2rem;
      padding: 10px;
      cursor: pointer;
    }

    &>.menuDropdown {
      position: absolute;
      top: 120%;
      left: 1rem;
      background: white;
      border-radius: 10px;
      border: 1px solid #dddddd;
      box-shadow: 0px 4px 4px 0px #5A565640;
      padding: 0.5rem;
      width: 300px;
      max-height: 80vh;
      overflow-y: scroll;
      display: flex;

      &::-webkit-scrollbar {
        width: 0px !important;
        height: 0px !important;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &>.mainMenu {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        padding: 0.5rem 0;

        &>.eachItem {
          display: flex;
          align-items: center;
          gap: 1rem;
          padding: 0.3rem 1rem;
          border-radius: 15px 0 0 15px;
          cursor: pointer;
          transition: all linear 0.3s;

          &>.imageDiv {
            // border: 1px solid #dddddd;
            // padding: 5px;
            border-radius: 50%;
            height: 2rem;
            width: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background: white;

            &>img {
              width: 75%;
              object-fit: contain;
            }
          }

          &> :nth-child(2) {
            font-size: 0.9rem;
            font-weight: 400;
            white-space: nowrap;
          }
        }

        &>.eachItemSelected {
          background: #f1f4f6bf;
        }
      }
    }
  }

  &>.loginCard {
    background: white;
    display: flex;
    border: 1px solid #e7e7e7;
    width: 70%;
    height: 85%;
    position: relative;

    &>.leftCard {
      width: 60%;
      padding: 3rem;
      border-right: 1px solid #e7e7e7;
      padding-bottom: 4rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &>img {
        height: 6rem;
        object-fit: contain;
        width: max-content;
      }

      &>.credentialsContainer {
        margin-top: 2.5rem;
        display: flex;
        flex-direction: column;
        gap: 1.7rem;

        &>.title {
          font-size: 1.5rem;
          font-weight: 520;
        }

        .inputContainer {
          border: 0.5px solid #e5e5e5;
          padding: 1rem;
          background: #f5f5f5;
          font-size: 1rem;
          display: block;
          outline: none;
          font-weight: 400;
          border-radius: 5px;
          max-width: 450px;
          width: 80%;
        }

        .loginMessage {
          font-size: 1rem;
          font-weight: 500;
          color: #d6a86b;
          padding: 0.25rem 0;
        }

        .submitBtn {
          border-radius: 5px;
          max-width: 450px;
          width: 80%;
          padding: 1rem;
          font-size: 1rem;
          outline: none;
          font-weight: 400;
          color: white;
          background: var(--main-color);
          border: 1px solid var(--main-color);
          outline: none;
          border: none;
          cursor: pointer;
          transition: all linear 0.3s;

          &:hover {
            scale: 1.05;
          }
        }

        .normalBtn {
          font-size: 1rem;
          font-weight: 500;
          cursor: pointer;
          text-align: center;
          max-width: 450px;
          width: 80%;
          transition: all linear 0.3s;

          &:hover {
            text-decoration: underline;
          }
        }

        &>.seperator {
          font-weight: 600;
          font-size: 1rem;
          position: relative;
          max-width: 450px;
          width: 80%;
          text-align: center;

          &>span {
            background: white;
            padding: 0 0.5rem;
            position: relative;
            z-index: 1;
          }

          &::before {
            position: absolute;
            content: "";
            height: 1px;
            background: #e5e5e5;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .transparentBtn {
          border-radius: 5px;
          max-width: 450px;
          width: 80%;
          padding: 1rem;
          font-size: 1rem;
          outline: none;
          font-weight: 550;
          color: var(--font-color);
          border: 1px solid #e5e5e5;
          outline: none;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          transition: all linear 0.3s;

          &>img {
            width: 1rem;
            height: 1rem;
          }

          &:hover {
            background: #e5e5e5;
            scale: 1.05;
          }
        }
      }
    }

    &>.rightCard {
      width: 40%;
      background: var(--main-color);
      display: flex;
      justify-content: center;
      align-items: center;

      &>img {
        width: 7rem;
        object-fit: contain;
      }
    }

    &>.bottomMessage {
      position: absolute;
      left: 0%;
      right: 0;
      bottom: -2.5rem;
      text-align: center;
      font-weight: 600;
      font-size: 1rem;

      &>a {
        background: var(--main-color);
        cursor: pointer;
        text-decoration: none;
        transition: all linear 0.3s;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &>.userRegistration {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      width: 100%;

      &>.userRegistrationContainer {
        width: 100%;

        &>.flexParent {
          display: flex;
          align-items: center;
          gap: 1rem;

          &>input {
            width: 50%;
            max-width: unset;
          }

          &>.checkDiv {
            border: none;
            display: flex;
            position: relative;

            &>input {
              border: none;
              width: -webkit-fill-available;
              max-width: unset;
            }

            &>.checkLight {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background: red;
              position: absolute;
              right: 5px;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }

          &>textarea {
            border: 0.5px solid #e5e5e5;
            padding: 1rem;
            background: #f5f5f5;
            font-size: 1rem;
            display: block;
            outline: none;
            font-weight: 450;
            border-radius: 5px;
            width: 50%;
            font-family: inherit;
            max-width: unset;
          }

          &>button {
            width: 50%;
            max-width: unset;
            display: flex;
            justify-content: center;
          }

          &>div {
            height: 3.25rem;
            width: 50%;

            &>div {
              background: #f5f5f5;
              border: 0.5px solid #e5e5e5;
              max-width: unset;
            }
          }

          &>.profileContainer {
            display: flex;
            align-items: center;
            gap: 1rem;
            color: rgba(0, 0, 0, 0.9);
            font-size: 0.9rem;
            font-weight: 450;
            width: 50%;
            height: unset;

            &>.imageInput {
              width: -webkit-fill-available;
              background: none;
              border: none;

              img {
                width: 100%;
                height: 6rem;
                border-radius: 5px;
                border: 0.5px solid #e5e5e5;
              }

              &>.imageLabel {
                white-space: nowrap;
                text-align: center;
                margin-top: 1rem;
                color: rgba(0, 0, 0, 0.9);
                font-size: 0.9rem;
                font-weight: 450;
                cursor: pointer;
              }

              &>.emptyProfile {
                width: 100%;
                height: 6rem;
                border-radius: 5px;
                background: #f5f5f5;
                border: 0.5px solid #e5e5e5;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all linear 0.3s;

                &>img {
                  width: 3rem;
                  height: 3rem;
                  border: none;
                  transition: all linear 0.3s;
                }

                &:hover {
                  border: 0.5px solid var(--main-color);

                  &>img {
                    height: 4rem;
                    width: 4rem;
                  }
                }

                @keyframes heartbeat {
                  0% {
                    transform: scale(1);
                  }

                  50% {
                    transform: scale(1.2);
                  }

                  100% {
                    transform: scale(1);
                  }
                }
              }

              &>.emptyProfileLength {
                height: 6rem;
                width: 100%;
                border-radius: 5px;
                background: #f5f5f5;
                border: 0.5px solid #e5e5e5;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all linear 0.3s;

                &>img {
                  width: 3rem;
                  height: 3rem;
                  border: none;
                  transition: all linear 0.3s;
                }

                &:hover {
                  border: 0.5px solid var(--main-color);

                  &>img {
                    height: 4rem;
                    width: 4rem;
                  }
                }

                @keyframes heartbeat {
                  0% {
                    transform: scale(1);
                  }

                  50% {
                    transform: scale(1.2);
                  }

                  100% {
                    transform: scale(1);
                  }
                }
              }
            }

            &>.editIcon {
              all: unset;
              cursor: pointer;
            }
          }
        }
      }
    }

    &>.backButton {
      position: absolute;
      white-space: nowrap;
      left: 1rem;
      top: 1rem;
      text-decoration: underline;
      cursor: pointer;
      font-size: 0.85rem;
      font-weight: 550;
      color: var(--font-color);
      transition: all linear 0.3s;

      &:hover {
        scale: 1.05;
      }
    }
  }
}