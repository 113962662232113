:root {
  --main-color: linear-gradient(89.14deg, #F04E8C 0.79%, #F8BA4E 143.99%);


  --font-color: #5A5656;
  --light-bg: #fbfbfb !important;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat";
}

.Toastify__toast-container {
  top: 3.4rem !important
}

#root {
  width: 100vw;
  overflow-x: hidden;
}

body {
  color: var(--font-color);
  font-family: "Montserrat";
  width: 100vw;
}

::-webkit-scrollbar {
  display: none;
  width: 0px;
  /* Set the width of the table's scrollbar */
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 7px;
}

.globalLoading {
  position: fixed;
  z-index: 999;
  inset: 0;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.globalLoading>img {
  width: 200px;
  height: 200px;
  transition: all linear 0.3s;
  animation: heartbeat 1s infinite;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 2000px) {
  html {
    font-size: 100%;
  }
}

@media screen and (max-width: 1600px) {
  html {
    font-size: 95%;
  }
}

@media screen and (max-width: 800px) {
  html {
    font-size: 86%;
  }
}